import request from '@/utils/http';
//goods===============================================================
export function sharesChange_api(data) {
  return request({
    url: '/v12/user/reality_shares_change',
    method: 'post',
    data
  })
}

// 物流单号
export function shipments(data) {
  return request({
    url: '/v5/user/shipments',
    method: 'get',
    params: data
  })
}

// 审核详情
export function sharesChangeDetails_api(id) {
  return request({
    url: '/v11/user/sharesChange/'+id,
    method: 'get',
  })
}

// 行审核详情
export function sharesChangeDetailsNew_api(id) {
  return request({
    url: '/v12/user/reality_shares_change/'+id,
    method: 'get',
  })
}

// 行审核详情
export function sharesChangeDetailsNew_s_api(id) {
  return request({
    url: '/v12/user/electron_shares_change/'+id,
    method: 'get',
  })
}


// 审核支付
export function sharesChangePay_api(data,id) {
  return request({
    url: '/v11/user/sharesChangePay/'+id,
    method: 'put',
    data
  })
}

// 审核支付
export function cloneSharesChange_api(id) {
  return request({
    url: '/v12/user/reality_shares_change/'+id,
    method: 'delete',
  })
}


// 服务费支付订单
export function serviceOrder_api(data) {
  return request({
    url: '/v12/user/service_order',
    method: 'get',
    params:data
  })
}

// 选择合同
export function serviceContract_api(data) {
  return request({
    url: '/v12/user/contract',
    method: 'get',
    params:data
  })
}


// 校验身份
export function verify_api(data) {
  return request({
    url: '/v12/user/shares_buyer/1',
    method: 'get',
    params:data
  })
}


// 电子物料变更
export function electron_shares_change_api(data) {
  return request({
    url: '/v12/user/electron_shares_change',
    method: 'post',
    data
  })
}