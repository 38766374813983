<template>
  <div>
  <!-- 带箭头头部 -->
    <div class="header_items" v-if="headerConfig">
      <div class="header_contract">
        <div class="contranct_text1" style="margin-top:0" @click="goClone">
          <img style="margin-left:20px" class="left_icon" src="../assets/left.png" />
        </div>
      </div>
      <div class="header_center">{{headerConfig.title}}</div>
      <div style="margin-right:34px"></div>
      <div @click="mineMessage" class="header_right">
        <img class="messageIcon" src="../assets/userPhone1.png" />
        <div v-if="headerConfig" class="member_phone">客服</div>
        <div v-else>联系客服</div>
      </div>
    </div>
    <div style="display:flex;justify-content: space-between;" v-else>
      <div class="header_contract">
        <div :class="headerConfig?'contranct_text1 header_class':'contranct_text1'" @click="goClone">
          <img src="../assets/cloneIcon.png" />
          <div>返回</div>
        </div>
        <!-- <div class="contranct_text">详细操作流程查看></div> -->
      </div>
      <div @click="mineMessage" :class="headerConfig?'messageTitle3 header_member':'messageTitle3'">
        <img class="messageIcon" src="../assets/userPhone1.png" />
        <div v-if="headerConfig" class="member_phone">客服</div>
        <div v-else>联系客服</div>
      </div>
    </div>
  </div>

</template>
<script>
// import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      value: ""
    };
  },
  props: {
    headerConfig: {
      type: Object
    }
  },
  methods: {
    goClone() {
      this.$router.go(-1);
    },
    mineMessage() {
      // wx.closeWindow();
      this.$router.push("/lador/service");
    }
  }
};
</script>
<style type="text/css" scoped>
.header_contract img {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
.contranct_text1 {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000;
  margin-top: 20px;
}
.messageTitle3 .messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle3 {
  text-align: right;
  margin-right: 20px;
  font-size: 24px;
  margin-top: 20px;
  position: absolute;
  z-index: 10;
  top: 0px;
  right: 20px;
  color: #333;
}
.header_class {
  display: flex;
  margin-left: 20px;
}
.messageTitle3 {
  display: flex;
}
.member_phone {
  margin-top: 10px;
}
.header_center {
  text-align: center;
  font-size: 34px
}
.header_right {
  display: flex;
  font-size: 24px;
  position: absolute;
  right:34px;
  top:0px
}
.header_right img {
  width: 46px;
  height: 46px;
  margin-right: 10px;
  margin-top: 30px
}
.left_icon{
  width:13px;
  height:30px
}
.header_items{
  display:flex;justify-content: space-between;height:100px;line-height:100px;
  background-color: #fff;
}
</style>
